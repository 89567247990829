export type ApiObject = {
    label: string;
    specUrl: string;
    serverUrl: string;
    prod: boolean
};

export default function apis(): ApiObject[] {
    const demo = [
        {
            label: "[v1] White label",
            specUrl: "https://wl-pp.hifivework.com/v1/hfwWhiteLabel/openapi",
            serverUrl: "https://wl-pp.hifivework.com",
            prod: false
        },
        {
            label: "[v1] Hfw Core",
            specUrl: "/openapi/hfwcorev1Openapi.yml",
            serverUrl: "https://api-pp.hifivework.com",
            prod: false
        },
        {
            label: "[v2] Payslip",
            specUrl: "https://api-pp.hifivework.com/v2/payslip/openapi",
            serverUrl: "https://api-pp.hifivework.com",
            prod: false
        },
        {
            label: "[v2] Planning",
            specUrl: "https://api-pp.hifivework.com/v2/planning/openapi",
            serverUrl: "https://api-pp.hifivework.com",
            prod: false
        },
        {
            label: "[v2] GererMesAffaires",
            specUrl: "https://api-pp.hifivework.com/v2/gma/openapi",
            serverUrl: "https://api-pp.hifivework.com",
            prod: false
        },
        {
            label: "[v2] Oauth",
            specUrl: "https://api-pp.hifivework.com/v2/oauth/openapi",
            serverUrl: "https://api-pp.hifivework.com",
            prod: false
        },
        {
            label: "[v2] Maitre data",
            specUrl: "https://api-pp.hifivework.com/v2/maitredata/openapi",
            serverUrl: "https://api-pp.hifivework.com",
            prod: false
        },
        {
            label: "[v2] Expense report",
            specUrl: "https://api-pp.hifivework.com/v2/expensereport/openapi",
            serverUrl: "https://api-pp.hifivework.com",
            prod: false
        },
        {
            label: "[v2] Profile",
            specUrl: "https://api-pp.hifivework.com/v2/profile/openapi",
            serverUrl: "https://api-pp.hifivework.com",
            prod: false
        },
        {
            label: "[v2] Hfw Billing",
            specUrl: "https://api-pp.hifivework.com/v2/hfw-billing/openapi",
            serverUrl: "https://api-pp.hifivework.com",
            prod: false
        },
        {
            label: "[v2] Admin bitbucket",
            specUrl: "https://api-pp.hifivework.com/v2/bitbucket/openapi",
            serverUrl: "https://api-pp.hifivework.com",
            prod: false
        },
        {
            label: "[v2] Modules manager",
            specUrl: "https://api-pp.hifivework.com/v2/module-manager/openapi",
            serverUrl: "https://api-pp.hifivework.com",
            prod: false
        },
        {
            label: "[v2] CK Billing",
            specUrl: "https://api-pp.hifivework.com/v2/billing/openapi",
            serverUrl: "https://api-pp.hifivework.com",
            prod: false
        },
        {
            label: "[v2] End of month",
            specUrl: "https://api-pp.hifivework.com/v2/end-month/openapi",
            serverUrl: "https://api-pp.hifivework.com",
            prod: false
        },
        {
            label: "[v2] Modules",
            specUrl: "https://api-pp.hifivework.com/v2/modules/openapi",
            serverUrl: "https://api-pp.hifivework.com",
            prod: false
        },
        {
            label: "[v2] Chartered Accountant",
            specUrl: "https://api-pp.hifivework.com/v2/chartered-accountant/openapi",
            serverUrl: "https://api-pp.hifivework.com",
            prod: false
        },
        {
            label: "[v2] Notification",
            specUrl: "https://api-pp.hifivework.com/v2/notification/openapi",
            serverUrl: "https://api-pp.hifivework.com",
            prod: false
        },
        {
            label: "[v2] Shuttle sheet",
            specUrl: "https://api-pp.hifivework.com/v2/shuttle-sheet/openapi",
            serverUrl: "https://api-pp.hifivework.com",
            prod: false
        }
    ]

    const prod = [
        {
            label: "[v1] Hfw Core",
            specUrl: "/openapi/hfwcorev1Openapi.yml",
            serverUrl: "https://api.hifivework.com",
            prod: true
        },
        {
            label: "[v1] White label",
            specUrl: "https://wl.hifivework.com/v1/hfwWhiteLabel/openapi",
            serverUrl: "https://wl.hifivework.com",
            prod: true
        },
        {
            label: "[v2] Payslip",
            specUrl: "https://api.hifivework.com/v2/payslip/openapi",
            serverUrl: "https://api.hifivework.com",
            prod: true
        },
        {
            label: "[v2] Planning",
            specUrl: "https://api.hifivework.com/v2/planning/openapi",
            serverUrl: "https://api.hifivework.com",
            prod: true
        },
        {
            label: "[v2] GererMesAffaires",
            specUrl: "https://api.hifivework.com/v2/gma/openapi",
            serverUrl: "https://api.hifivework.com",
            prod: true
        },
        {
            label: "[v2] Oauth",
            specUrl: "https://api.hifivework.com/v2/oauth/openapi",
            serverUrl: "https://api.hifivework.com",
            prod: true
        },
        {
            label: "[v2] Maitre data",
            specUrl: "https://api.hifivework.com/v2/maitredata/openapi",
            serverUrl: "https://api.hifivework.com",
            prod: true
        },
        {
            label: "[v2] Expense report",
            specUrl: "https://api.hifivework.com/v2/expensereport/openapi",
            serverUrl: "https://api.hifivework.com",
            prod: true
        },
        {
            label: "[v2] Profile",
            specUrl: "https://api.hifivework.com/v2/profile/openapi",
            serverUrl: "https://api.hifivework.com",
            prod: true
        },
        {
            label: "[v2] Hfw Billing",
            specUrl: "https://api.hifivework.com/v2/hfw-billing/openapi",
            serverUrl: "https://api.hifivework.com",
            prod: true
        },
        {
            label: "[v2] Admin bitbucket",
            specUrl: "https://api.hifivework.com/v2/bitbucket/openapi",
            serverUrl: "https://api.hifivework.com",
            prod: true
        },
        {
            label: "[v2] Modules manager",
            specUrl: "https://api.hifivework.com/v2/module-manager/openapi",
            serverUrl: "https://api.hifivework.com",
            prod: true
        },
        {
            label: "[v2] CK Billing",
            specUrl: "https://api.hifivework.com/v2/billing/openapi",
            serverUrl: "https://api.hifivework.com",
            prod: true
        },
        {
            label: "[v2] End of month",
            specUrl: "https://api.hifivework.com/v2/end-month/openapi",
            serverUrl: "https://api.hifivework.com",
            prod: true
        },
        {
            label: "[v2] Modules",
            specUrl: "https://api.hifivework.com/v2/modules/openapi",
            serverUrl: "https://api.hifivework.com",
            prod: true
        },
        // {
        //     label: "[v2] Chartered Accountant",
        //     specUrl: "https://api.hifivework.com/v2/chartered-accountant/openapi",
        //     serverUrl: "https://api.hifivework.com",
        //     prod: true
        // },
        {
            label: "[v2] Notification",
            specUrl: "https://api.hifivework.com/v2/notification/openapi",
            serverUrl: "https://api.hifivework.com",
            prod: true
        // },
        // {
        //     label: "[v2] Shuttle sheet",
        //     specUrl: "https://api.hifivework.com/v2/shuttle-sheet/openapi",
        //     serverUrl: "https://api.hifivework.com",
        //     prod: true
        }
    ]

    return demo.concat(prod)
}