// @ts-nocheck
import React, {useMemo, useState} from 'react';
import {
    Box,
    Drawer,
    AppBar,
    CssBaseline,
    Toolbar,
    List,
    Typography,
    ListItemButton,
    ListItemText,
    createTheme,
    ThemeProvider,
    IconButton, Accordion, AccordionSummary, AccordionDetails
} from '@mui/material';
import {ExpandMore} from "@mui/icons-material"
import "rapidoc"

import {grey} from "@mui/material/colors";
import {Close, Menu} from "@mui/icons-material";
import apis, {ApiObject} from "./apis";

const theme = createTheme({
    palette: {
        primary: {
            main: grey[900],
        },
    }
})

const drawerWidth = 240;

function App() {
    const apisProd = useMemo<ApiObject[]>(() => apis().filter((elt) => elt.prod), [])
    const apisDemo = useMemo<ApiObject[]>(() => apis().filter((elt) => !elt.prod), [])

    const [activeApi, setActiveApi] = useState<ApiObject>(apisDemo[0])
    const [open, setOpen] = useState(true);

    const [expanded, setExpanded] = useState("demo");
    function handleChange(nextExpanded: string) {
        if (nextExpanded === expanded) {
            setExpanded("")
        } else {
            setExpanded(nextExpanded)
            switch (nextExpanded) {
                case "prod":
                    setActiveApi(apisProd[0])
                    break;
                case "demo":
                    setActiveApi(apisDemo[0])
                    break;
            }
        }
    }


    //https://mrin9.github.io/RapiDoc/api.html
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Toolbar>
                        {open ? (
                            <IconButton
                                color="inherit"
                                aria-label="close drawer"
                                onClick={() => setOpen(false)}
                                edge="start"
                                sx={{ mr: 2 }}
                            >
                                <Close />
                            </IconButton>
                        ) : (
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={() => setOpen(true)}
                                edge="start"
                                sx={{ mr: 2 }}
                            >
                                <Menu />
                            </IconButton>
                        )}
                        <Typography variant="h6" noWrap component="div">
                            Hifivework API Documentation
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="persistent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    }}
                    open={open}
                    style={open ? {transition: theme.transitions.create(['margin', 'width'], {
                            easing: theme.transitions.easing.easeOut,
                            duration: theme.transitions.duration.enteringScreen,
                        })} : {width: 0, transition: theme.transitions.create(['margin', 'width'], {
                            easing: theme.transitions.easing.easeOut,
                            duration: theme.transitions.duration.enteringScreen,
                        })}}
                >
                    <Toolbar />
                    <Box sx={{ overflow: 'auto' }}>
                        <Accordion disableGutters expanded={expanded === 'demo'} onChange={() => handleChange('demo')}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                    Démo
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    {apisDemo.map((elt) => (
                                        <ListItemButton key={elt.label+elt.serverUrl} onClick={() => setActiveApi(elt)} selected={elt.label === activeApi.label && !activeApi.prod}>
                                            <ListItemText primary={elt.label} />
                                        </ListItemButton>
                                    ))}
                                </List>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion disableGutters elevation={0} expanded={expanded === 'prod'} onChange={() => handleChange('prod')}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                    Production
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    {apisProd.map((elt) => (
                                        <ListItemButton key={elt.label+elt.serverUrl} onClick={() => setActiveApi(elt)} selected={elt.label === activeApi.label && activeApi.prod}>
                                            <ListItemText primary={elt.label} />
                                        </ListItemButton>
                                    ))}
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Drawer>
                <Box component="main" sx={{ flexGrow: 1 }}>
                    <Toolbar />
                    <rapi-doc
                        show-header={false}
                        theme={"light"}
                        persist-auth={true}
                        spec-url={activeApi.specUrl}
                        render-style="focused"
                        server-url={activeApi.serverUrl}
                        schema-style={"table"}
                        header-color={"#fff"}
                        primary-color={"#333"}
                        bg-color={"#fff"}
                        nav-bg-color={"#fff"}
                        show-info={false}
                        allow-server-selection={false}
                    />
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default App;
